import { render, staticRenderFns } from "./dishwasher_modalpanel.vue?vue&type=template&id=bafc96ec&scoped=true&"
import script from "./dishwasher_modalpanel.vue?vue&type=script&lang=js&"
export * from "./dishwasher_modalpanel.vue?vue&type=script&lang=js&"
import style0 from "./dishwasher_modalpanel.vue?vue&type=style&index=0&id=bafc96ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bafc96ec",
  null
  
)

export default component.exports