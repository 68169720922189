<template>
  <div class="relative w-full grid grid-cols-12">
    <dishpanel></dishpanel>
    <dishtabs></dishtabs>
  </div>
</template>

<script>
import dishpanel from '@/components/dishwasher_modalpanel.vue';
import dishtabs from '@/components/dishwasher_tabs.vue';
export default {
  props: {},
  components: {dishpanel,dishtabs},
  data() {
    return {
    };
  },
  computed: {},
  mounted() {
    // window.addEventListener('onresize', this.onResize.bind(this));
  },
  methods: {/**/ },
};
</script>
<style lang="scss" scope>
@import '../styles/typovars';
.text-grey {
  color: #979797;
}
.text-pgrey {
  color: #464444;
}
hr {
  border-color: #262524;
  opacity: 0.5;
  // border-width:1px;
}
.online-green {
  color: #00bd78;
}
.panel {
  background: #f3f3f3;
}
.tabs-menu {
  a {
    color: $ltgrey;
    white-space: nowrap;

    &.active span {
      padding:0px 4px;
      color: #0a0a09;
      position: relative;
      border-bottom: 2px solid #f2a900;
      // &:after {
      //   content: ' ';
      //   position: absolute;
      //   bottom: -1px;
      //   left: -50%;
      //   transform: translateX(50%);
      //   width: 94%;
      // }
    }
  }
}
.product-info {
  h4 {
    font-size: 14px;
    margin-bottom: 0px;
    color: #8e8e8e;
    font-weight: 400;
  }
  p {
    font-size: 18px;
    color: #0a0a09;
    margin-bottom: 18px;
  }
}
th {
  border-bottom: #444444 solid 2px;
}
td {
  padding-top: 12px;
  border-bottom: #dadada 1px solid;
}
</style>
