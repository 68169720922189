<template>
  <div class="panel col-span-3 rounded px-5 py-4">
    <div class="w-full grid grid-cols-2">
      <div class="font-extrabold">Dishwasher</div>
      <div class="font-extrabold online-green text-right text-sm">Online</div>
    </div>
    <div class="w-full grid grid-cols-2 pt-8 pb-3">
      <div><img src="@/assets/img/dishwasher_panel_icon.png" class="float-left" /></div>
      <div><img src="@/assets/img/radiobutton_power.svg" class="float-right" /></div>
    </div>
    <hr class="mt-5 mb-3" />
    <div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">MODE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/dropdown_mode_normal.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey col-span-2 my-10 py-10"></div>
      </div>
    </div>
    <hr class="mt-5 mb-3" />
    <div class="w-full grid grid-cols-2 pb-2">
      <div class="font-extrabold text-grey text-sm align-middle m-auto mx-0">AUX SETTINGS</div>
      <div>
        <img src="@/assets/img/arrowToggle_closed.svg" class="arrow relative float-right" />
      </div>
    </div>
    <div class="w-full py-4 px-10 set text-center align-middle m-auto block">
      <a class="w-full rounded text-white m-auto text-center bg-black block"
        ><span class="block px-10 pt-3 font-extrabold">Set</span></a
      >
    </div>
    <div class="w-full grid grid-cols-2 pb-2">
      <router-link to="/assign-task" class="text-black text-xs align-middle m-auto mx-0 font-bold">Assign Task ></router-link>
      <div class="text-black text-xs align-middle m-auto mx-0 text-right font-bold">
        Remove >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showToggle: true,
    };
  },
  methods: {
    toggle: function() {
      this.showToggle = !this.showToggle;
    },
  },
};
</script>
<style lang="scss" scoped>
.panel {
  line-height: 1em;
}
.arrow {
  cursor: pointer;
}
.set {
  a {
    border-radius: 14px;
    height: 41px;
  }
}
.border-bottom {
  border-bottom: 1px solid #d0d0d0;
}
</style>
